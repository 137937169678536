import React from "react";
import { BsArrowUpShort } from "react-icons/bs";
import useTokenBalance from "../../../hash/useTokenBalance"
import styles from "./Sidebar.module.css";
import {
  dashboard,
  myStakes,
  transaction,
  plans,
  profile,
  contact,
  activeDashboard,
  activeTransaction,
  activePlans,
  activeProfile,
  leaderBoard,
  activeLeaderBoard,
  investment,
  activeInvestment,
} from "../../../images/image";
import { NavLink } from "react-router-dom";
import { useAccount } from "wagmi";
import { useTotalReferralEarned, useTotalWithdrawRewards, useStakedAmount } from "../../../hash/useContractUniversal";
import TransferBalance from "../../OurPlans/Popup/TransferBalance";
const Sidebar = ({ sidebar, setSidebar }) => {
  const { address } = useAccount();
  const [price, setPrice] = React.useState(0);
  const balance = Number(useTokenBalance(address))?.toFixed(2)
  const stakes = Number(useStakedAmount(address))?.toFixed(2)
  const totalWithdrawRewards = Number(useTotalWithdrawRewards(address))?.toFixed(2)
  const [navItems, setNavItems] = React.useState([
    {
      icons: [dashboard, activeDashboard],
      navItem: "Dashboard",
      to: "/",
    },
    {
      icons: [myStakes, activeTransaction],
      navItem: "Staking History",
      to: "/history",
    },
    {
      icons: [transaction, activeTransaction],
      navItem: "Buy UNS",
      to: "https://sale.uns.technology",
    }]);
  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

  const getUnsPrice = async () => {
    let url = "https://api.coingecko.com/api/v3/simple/price?ids=uns-token&vs_currencies=usd"
    const res = await fetch(url)
    const data = await res.json()
    setPrice((data["uns-token"].usd).toFixed(2))
  }

  React.useEffect(() => {
    let additional_nav_items = [{
      icons: [transaction, activeTransaction],
      navItem: "Leaderboard",
      to: "/leaderboard",
    },
    {
      icons: [transaction, activeTransaction],
      navItem: "Old Leaderboard",
      to: "https://old-staking.uns.technology/leaderboard",
    }];

    if (address) {
     setNavItems([...navItems, ...additional_nav_items])
    } else {
     
    }
    getUnsPrice()
    // alert("Please note that the leaderboard is currently being updated. It will be available soon.")
  }, [address])
  return (
    <section
      className={[styles.sidebar, sidebar && styles.showSidebar].join(" ")}
    >

      <div className={styles.navContainer}>
        {" "}
        <div>
          <p className={styles.navHeading}>Menu</p>
          <div className={styles.navItems}>
            {navItems.map((el, i) => (
              <NavLink
                to={el.to}
                key={i}
                className={({ isActive }) =>
                  isActive
                    ? [styles.navItem, styles.navActive].join(" ")
                    : styles.navItem
                }
                onClick={() => setSidebar((prev) => !prev)}
              >
                {/* {({ isActive, isPending }) => {
                  isActive ? (
                    <img src={el.icons[0]} alt="#" className={styles.navIcon} />
                  ) : (
                    <img src={el.icons[1]} alt="#" className={styles.navIcon} />
                  )
                }} */}
                {({ isActive, isPending }) => {
                  return (
                    <>
                      <img
                        src={isActive ? el.icons[1] : el.icons[0]}
                        alt="#"
                        className={styles.navIcon}
                      />
                      <span>{el.navItem}</span>
                    </>
                  );
                }}
              </NavLink>
            ))}
          </div>
        </div>
        {/* <div>
          <p className={styles.navHeading}>Additional</p>
          <NavLink
            to="/home"
            className={({ isActive }) =>
              isActive
                ? [styles.navItem, styles.navActive].join(" ")
                : styles.navItem
            }
            onClick={() => setSidebar((prev) => !prev)}
          >
            HOMEPAGE
          </NavLink>
        </div> */}
      </div>{" "}
      {/* <NavLink
        to="/contact"
        className={({ isActive }) =>
          [
            styles.navItem,
            styles.contactTab,
            isActive && styles.navActive,
          ].join(" ")
        }
        onClick={() => setSidebar((prev) => !prev)}
      >
        <img src={contact} alt="#" className={styles.navIcon} />
        <span>Contact</span>
      </NavLink> */}
    </section>
  );
};

export default Sidebar;
