import React, { useEffect, useState } from "react";
import styles from "./DataTable.module.css";
import axios from "axios";
import SingleRow from "./SingleRow";
import { useAccount } from "wagmi";
import { getAddress } from "ethers";
const DataTable = () => {
  const { address } = useAccount();
  const [data, setData] = useState([])
  const getData = async () => {
    let user = getAddress(address).toLowerCase();
    console.log(user)
    let api = "https://api.studio.thegraph.com/query/82380/uns-universal-staking/version/latest"
    let query = `{
    activities(where : {user : "${user}"}) {
        id
        user
        type
        blockTimestamp
        amount
    }
  }`
    const response = await axios.post(api, { query })
    setData(response.data.data.activities)
    console.log(response.data.data.activities)
    console.log(user)
  }

  useEffect(() => {
    getData()
  }, [address])

  const [activeTab, setActiveTab] = useState(0);
  const tabs = ["All", "Rewards", "Stake", "Unstake"];

  const filteredData = data.filter((item) => {
    if (tabs[activeTab].toLowerCase() === "all") {
      return data;
    } else {
      return item.type.toLowerCase() === tabs[activeTab].toLowerCase();
    }
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabContainer}>
        {tabs.map((el, i) => (
          <div
            key={i}
            onClick={() => setActiveTab(i)}
            className={styles.tabTextWrapper}
          >
            <p
              className={[styles.tab, activeTab === i && styles.activeTab].join(
                " "
              )}
            >
              {" "}
              {el}
            </p>
            {el.toLowerCase() === tabs[activeTab].toLowerCase() &&
              tabs[activeTab].toLowerCase() !== "history" && (
                <span className={styles.totalItem}>{filteredData.length}</span>
              )}
          </div>
        ))}
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <tbody>
            <tr className={styles.row}>
              <th className={[styles.heading].join(" ")}>ID</th>
              {/* <th className={styles.heading}>Action</th> */}
              <th className={styles.heading}>Date</th>
              <th className={styles.heading}>Amount</th>
              <th className={styles.heading}>Action</th>
            </tr>
            {filteredData.map((el, index) => (
              <SingleRow {...el} key={index} index={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DataTable;
