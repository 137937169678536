import React, { useEffect } from "react";
import styles from "./InvestmentAccount.module.css";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import useTokenBalance from "../../../hash/useTokenBalance";
import {
  useStakedAmount,
  useTotalWithdrawRewards,
} from "../../../hash/useContractUniversal";

import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from "@rainbow-me/rainbowkit";
//

const InvestmentAccount = ({ setTransferFunds, setBal, setMigrate }) => {
  const { openConnectModal } = useConnectModal();
  const { address, isConnected } = useAccount();

  let balance = Number(useTokenBalance(address))?.toFixed(2);
  let totalStaked = Number(useStakedAmount(address))?.toFixed(2);

  useEffect(() => {
    setBal(balance);
  }, [balance]);




  const button = () => {
    if (!address) {
      return {
        text: "Connect Wallet",
        onPress: openConnectModal,
      };
    } else {
      return {
        text: "Stake Now",
        onPress: () => setTransferFunds(true),
      };
    }
  };
  const formatNumber = (number) =>
    number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  return (
    <div className={styles.investmentAccount}>
      <h4 className={styles.title}>Balance Overview</h4>
      <div className={styles.amountContainer}>
        <div>
          <h4 className={styles.availableAmount}>
            {balance} {process.env.REACT_APP_TOKEN_SYMBOL}
          </h4>
          <p className={styles.text}>
            Available {process.env.REACT_APP_TOKEN_SYMBOL}
          </p>
        </div>
        <div>
          <h4 className={styles.availableAmount}>
            {totalStaked} {process.env.REACT_APP_TOKEN_SYMBOL}
          </h4>
          <p className={styles.text}>
            Staked {process.env.REACT_APP_TOKEN_SYMBOL}
          </p>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        {
          address &&
          <> <button className={styles.button} onClick={
            () => {
              window.location.href = "https://sale.uns.technology"
            }
          }>
            {"BUY UNS"}
          </button>
            {/* <button className={styles.button} onClick={() => {
              setMigrate(true)
            }}>
              {"Migrate"}
            </button> */}

          </>}
        <button className={styles.button} onClick={button().onPress}>
          {button().text}
        </button>

      </div>
    </div>
  );
};

export default InvestmentAccount;
