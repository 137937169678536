import React from "react";
import styles from "./Actions.module.css";
import formatNumber from "../../utils";
import { useAccount } from "wagmi";
import { useIsLocked } from "../../../hash/useContractUniversal";

const SingleAction = ({ title, value, inUsd, buttonText, action, setIsLockedv }) => {
  const { address } = useAccount();
  const isLocked = useIsLocked(address);

  console.log(isLocked, "isLocked")
  return (
    <div className={styles.singleAction}>
      <h4 className={styles.heading}>{title}</h4>

      <h3 className={styles.balance}>{formatNumber(value)} {process.env.REACT_APP_TOKEN_SYMBOL}</h3>
      <p className={styles.inUsd}>${inUsd}</p>
      {
        isLocked ? <button className={styles.disabled} disabled={true}>{title == "Unclaimed Rewards" ? "TO BCT MEMBERSHIP" : buttonText.toUpperCase()}</button> : <button className={styles.button} onClick={action}>{buttonText.toUpperCase()}</button>
      }
    </div>
  );
};

export default SingleAction;
