import React from "react";
import styles from "./Heading.module.css";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import { useAccount } from 'wagmi'

const Heading = () => {
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { address, isConnected } = useAccount()

  function mywallet() {
    if (!address) {
      return {
        text: 'Connect Wallet',
        onPress: openConnectModal
      }
    } else {
      return {
        text: 'View Wallet',
        onPress: openAccountModal
      }
    }
  }
  return (
    <section className={styles.wrapper}>
      <div className={styles.header}>
        <h3 className={styles.heading}>Staking Dashboard</h3>
        <div className={styles.buttonContainer}>
          <button
            onClick={mywallet().onPress}
            className={[styles.button, styles.deposit].join(" ")}>
            {mywallet().text}
          </button>
          {/* <button className={[styles.button, styles.investAndEarn].join(" ")}>
            Invest and Earn
          </button>
          <button className={[styles.button, styles.deposit].join(" ")}>
            Deposit
          </button> */}
        </div>
      </div>
      <p 
      onClick={() => {
        window.open('https://docs.google.com/document/d/107rnCWwrI6BpV2kEVjoYEjwK4iTCBQb4/edit?usp=sharing&ouid=104345514601730377212&rtpof=true&sd=true', '_blank')
      }}
      className={styles.text}>At a glance summary of your stakes.</p>
    </section>
  );
};

export default Heading;
