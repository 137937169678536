import React, { useEffect, useState } from "react";
import Input from "./Input/Input";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import styles from "./TransferBalance.module.css";
import { useAccount } from "wagmi";
import { calender } from "../../../images/image";
import { formatEther } from "ethers";
import { parseEther } from "ethers";
import { toast } from "react-toastify";
import DropDown from "./DropDown/DropDown";
import { ethers } from "ethers";
import { useInitiateStake, useIsBuyer, useIsRecurrentStaker } from "../../../hash/useContractUniversal";
import { useMigrate } from "../../../hash/useContractUniversal";
import useTokenBalance from "../../../hash/useTokenBalance";
import { useApproveToken, useTokenAllowance } from "../../../hash/useTokenBalance";
let abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "stakeID",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "unlockTime",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "APY",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ref1",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ref2",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ref3",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ref4",
        type: "address",
      },
      {
        indexed: false,
        internalType: "address",
        name: "ref5",
        type: "address",
      },
    ],
    name: "OnStake",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "stakeID",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Rewards",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "stakeID",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
    ],
    name: "Unstake",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "accountCoolDown",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "allStakes",
    outputs: [
      {
        internalType: "uint256",
        name: "id",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "unlockTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "stakeTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "withdrawnAmount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "lastWithdrawTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "APY",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "staker",
        type: "address",
      },
      {
        internalType: "bool",
        name: "status",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_stakeID",
        type: "uint256",
      },
    ],
    name: "calculateReward",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "cooldown",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "feeTo",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_unlockTime",
        type: "uint256",
      },
    ],
    name: "getAPY",
    outputs: [
      {
        internalType: "uint256",
        name: "_apy",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_stakeID",
        type: "uint256",
      },
    ],
    name: "getElapsedTime",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_stakeID",
        type: "uint256",
      },
    ],
    name: "getStakeByID",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "id",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "unlockTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "stakeTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "withdrawnAmount",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "lastWithdrawTime",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "APY",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "staker",
            type: "address",
          },
          {
            internalType: "bool",
            name: "status",
            type: "bool",
          },
        ],
        internalType: "struct UniversalStake.stakes",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getStakeSize",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_token",
        type: "address",
      },
    ],
    name: "initialize",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "isInitialized",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "minimumStake",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "myTotalStakes",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "myTotalWithdrawnRewards",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "referralBonusEarned",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    name: "referrer",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "rewardSchedule",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_unlockTime",
        type: "uint256",
      },
    ],
    name: "stake",
    outputs: [
      {
        internalType: "bool",
        name: "_response",
        type: "bool",
      },
    ],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "token",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_stakeID",
        type: "uint256",
      },
    ],
    name: "unstake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "userStakes",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_stakeID",
        type: "uint256",
      },
    ],
    name: "withdrawReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];


const TransferBalance = ({ setPopup, balance }) => {

  const [date, setDate] = useState(new Date());
  const [block, setBlock] = useState(0);
  const [hashData, setHashData] = useState(0);
  const { address } = useAccount();
  // const dropDownItems = ["3 Months - 12.5% APY", "6 Months - 25% APY", "9 Months - 37.5% APY", "12 Months - 50% APY"];
  const dropDownItems = ["3 Months - 2% APY", "6 Months - 3.125% APY", "9 Months - 5% APY", "12 Months - 12% APY"];

  const [duration, setDuration] = useState("3 Months - 2% APY");
  const [selectedDurationId, setSelectedDurationId] = useState(1);
  let bal = Number(useTokenBalance(address))?.toFixed(2);

  const [values, setValues] = useState({
    availableFunds: balance,
    amountToTransfer: 0,
  });
  let rpc_url = "https://bsc-dataseed3.ninicoin.io	"
  let provider = new ethers.JsonRpcProvider(rpc_url);

  const initiateStake = useInitiateStake(
    process.env.REACT_APP_UNIVERSAL_CONTRACT,
    String(values.amountToTransfer),
    selectedDurationId,
  );
  const isRecurrentStaker = useIsRecurrentStaker(process.env.REACT_APP_UNIVERSAL_CONTRACT, address);
  const isBuyer = useIsBuyer(process.env.REACT_APP_UNIVERSAL_CONTRACT, address);
  const allowance = useTokenAllowance(address);
  const approve = useApproveToken()

  console.log(isRecurrentStaker, "isRecurrentStaker")
  console.log(isBuyer, "isBuyer")

  const stakeButton = () => {
    console.log("allowance", allowance)
    console.log(typeof (allowance))

    console.log("values.amountToTransfer", values.amountToTransfer)
    console.log(typeof (values.amountToTransfer))
    if (!address) {
      return {
        text: "Connect Wallet",
        func: () => { }
      }
    } else if (!isBuyer && !isRecurrentStaker) {
      // toast.error("You must purchase UNS to stake", {
      //   toastId: "buyTokens"

      // })
      return {
        text: "Buy Tokens",
        func: () => {
          window.location.href = "https://sale.uns.technology"
        }
      }
    }


    else if (parseInt(allowance) < parseInt(values.amountToTransfer)) {
      return {
        text: "Approve",
        func: approve
      }
    } else {
      return {
        text: "Stake",
        func: () => {
          if (values.amountToTransfer >= 100) {
            initiateStake()
          } else {
            toast.error("Minimum amount to stake is 100")
          }
        }
      }
    }
  };

  const sb = stakeButton()

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className={[styles.input, styles.datePicker].join(" ")}
      onClick={onClick}
      ref={ref}
    >
      {value}
      <img src={calender} alt="#" className={styles.calender} />
    </button>
  ));
  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>
              STAKE {process.env.REACT_APP_TOKEN_SYMBOL}
            </h2>
            <p className={styles.text}></p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            <Input
              label="UNS Balance"
              type="number"
              name="availableFunds"
              text=""
              value={bal}

            />
            <Input
              label="Stake Amount"
              type="number"
              name="amountToTransfer"
              placeholder="Enter Amount"
              text=""
              onChange={onChange}
            />
            <DropDown
              setSelectedDurationId={setSelectedDurationId}
              dropDownItems={dropDownItems}
              label="Stake Period"
              currentItem={duration}
              setCurrenItem={setDuration}
            />
            {/* <div className={styles.inputContainer}>
              <label className={styles.label}>Unlock Date - {block}</label>
              <DatePicker
                selected={date}
                onChange={(date) => {
                  setDate(date);
                  setBlock(date.getTime() / 1000);
                }}
                className={styles.input}
                customInput={<CustomInput />}
                dateFormat="dd MMM yyyy"
              />
            </div> */}
            <div className={styles.buttonContainer}>
              <button
                className={[styles.button, styles.transferButton].join(" ")}
                onClick={(e) => {
                  e.preventDefault()
                  sb.func()
                }}
              >
                {sb.text}
              </button>
              <button className={styles.button} onClick={() => setPopup(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className={styles.suggestionContainer}>
          {/* <p className={styles.suggestion}>
            The amount will immediately transfer into your main account so you
            can withdraw funds or re-investment.
          </p> */}
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setPopup(false)}></div>
    </>
  );
};


export const Migrate = ({ setPopup, balance }) => {
  const [date, setDate] = useState(new Date());
  const [block, setBlock] = useState(0);
  const [hashData, setHashData] = useState(0);
  const { address } = useAccount();
  const dropDownItems = ["3 Months - 6.25% APY", "6 Months - 12.5% APY", "9 Months - 18.75% APY", "12 Months - 25% APY"];
  const [duration, setDuration] = useState("3 Months - 25% APY");
  const [selectedDurationId, setSelectedDurationId] = useState(1);

  const [values, setValues] = useState({
    availableFunds: balance,
    amountToTransfer: 0,
  });
  let rpc_url = "https://bsc-dataseed3.ninicoin.io	"
  let provider = new ethers.JsonRpcProvider(rpc_url);

  const initiateStake = useInitiateStake(
    process.env.REACT_APP_UNIVERSAL_CONTRACT,
    String(values.amountToTransfer),
    selectedDurationId,
  );

  const migrateUNS = useMigrate();

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: +e.target.value });
  };
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <button
      className={[styles.input, styles.datePicker].join(" ")}
      onClick={onClick}
      ref={ref}
    >
      {value}
      <img src={calender} alt="#" className={styles.calender} />
    </button>
  ));
  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>
              MIGRATE {process.env.REACT_APP_TOKEN_SYMBOL}
            </h2>
            <p className={styles.text}>Migrate Your UNS from old staking platform</p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {/* <Input
              label="UNS Balance"
              type="number"
              name="availableFunds"
              text=""
              value={values["availableFunds"]}
            />
            <Input
              label="Stake Amount"
              type="number"
              name="amountToTransfer"
              placeholder="Enter Amount"
              text=""
              onChange={onChange}
            />
            <DropDown
              setSelectedDurationId={setSelectedDurationId}
              dropDownItems={dropDownItems}
              label="Stake Period"
              currentItem={duration}
              setCurrenItem={setDuration}
            /> */}
            {/* <div className={styles.inputContainer}>
              <label className={styles.label}>Unlock Date - {block}</label>
              <DatePicker
                selected={date}
                onChange={(date) => {
                  setDate(date);
                  setBlock(date.getTime() / 1000);
                }}
                className={styles.input}
                customInput={<CustomInput />}
                dateFormat="dd MMM yyyy"
              />
            </div> */}
            <div className={styles.buttonContainer}>
              <button
                className={[styles.button, styles.transferButton].join(" ")}
                onClick={() => {
                  migrateUNS();
                }}
              >
                Migrate
              </button>
              <button className={styles.button} onClick={() => setPopup(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className={styles.suggestionContainer}>
          {/* <p className={styles.suggestion}>
            The amount will immediately transfer into your main account so you
            can withdraw funds or re-investment.
          </p> */}
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setPopup(false)}></div>
    </>
  );
};

export default TransferBalance;

