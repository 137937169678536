import React from "react";
import formatNumber from "../../utils";
import styles from "./AmountInvested.module.css";
import { history, transferCard } from "../../../images/image";
import ChartComponent from "./Chart/Chart";
import { useTotalWithdrawRewards } from "../../../hash/useContractUniversal";
import { useTotalReferralEarned } from "../../../hash/useContractUniversal";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";
import { useStakedAmount } from "../../../hash/useContractUniversal";


const AmountInvested = () => {
  const balance = useStakedAmount();
  const { address } = useAccount();
  const totalWithdrawRewards = Number(useTotalWithdrawRewards())?.toFixed(2);
  const totalReferralEarned = Number(
    useTotalReferralEarned(process.env.REACT_APP_UNIVERSAL_CONTRACT, address)
  )?.toFixed(2);

  function copyToClipboard(text) {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Append the textarea element to the document body
    document.body.appendChild(textarea);

    // Copy the contents of the textarea to the clipboard
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea element
    document.body.removeChild(textarea);
  } return (
    <div className={styles.amountInvested}>
      <div className={styles.wrapper}>
        <h4 className={styles.title}>Earnings Overview </h4>
        <div className={styles.amountContainer}>
          <div>
            <h4 className={styles.investedAmount}>
              {totalWithdrawRewards}{" "}
              <span className={styles.currency}>
                {process.env.REACT_APP_TOKEN_SYMBOL}
              </span>{" "}
            </h4>
            <p className={styles.text}>
              {process.env.REACT_APP_TOKEN_SYMBOL} Withdrawn
            </p>
          </div>
          <div>
            <h4 className={styles.investedAmount}>
              +{totalReferralEarned} {process.env.REACT_APP_TOKEN_SYMBOL}{" "}
            </h4>
            <p className={styles.text}>Referral Bonus</p>
          </div>
        </div>

        {/* <ChartComponent /> */}
      </div>

      <div className={styles.bottomBar}>
        {balance >= 99 && <><div className={styles.box}>
          <img
            onClick={() => {
              //copy to clipboard
              // navigator.clipboard.writeText(`https://sale.uns.technology/${address}`)
              toast.success("Copied to clipboard")
              // window.location.href = "/leaderboard";
              copyToClipboard(`https://sale.uns.technology/${address}`)
            }}
            src={history}
            alt="#"
            className={styles.icon}
          />
          <p
            onClick={() => {
              // //copy to clipboard
              // navigator.clipboard.writeText(`https://sale.uns.technology/${address}`)
              toast.success("Copied to clipboard")
              // // window.location.href = "/leaderboard";
              copyToClipboard(`https://sale.uns.technology/${address}`)
            }}
            className={styles.bottomText}
          >
            Copy Referral Link
          </p>
        </div>
        </>}
        {/* <div className={styles.box}>
          <img
            onClick={() => {
              window.location.href = "/history";
            }}
            src={transferCard}
            alt="#"
            className={styles.icon}
          />
          <p
            onClick={() => {
              window.location.href = "/history";
            }}
            className={styles.bottomText}
          >
            Staking History
          </p>
        </div>
        <div className={styles.box}>
          <img
            onClick={() => {
              window.location.href = "/leaderboard";
            }}
            src={history}
            alt="#"
            className={styles.icon}
          />
          <p
            onClick={() => {
              window.location.href = "/leaderboard";
            }}
            className={styles.bottomText}
          >
            Leaderboard
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default AmountInvested;
