import React, { useState } from "react";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { AiOutlineAlignRight } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import Sidebar from "./Sidebar/Sidebar";

import { germany, logo, userImage } from "../../images/image";
import styles from "./Header.module.css";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [language, setLanguage] = useState({
    flag: germany,
    country: "German",
  });
  const allLanguage = [
    { flag: germany, country: "German" },
    { flag: germany, country: "German" },
    { flag: germany, country: "German" },
    { flag: germany, country: "German" },
  ];
  return (
    <>
      <Sidebar sidebar={sidebar} setSidebar={setSidebar} />
      <section className={styles.headerWrapper}>
        <div className={styles.header}>
          <div
            onClick={() => {
              window.location.href = "https://uns.technology";
            }}
            className={styles.logoContainer}>
            <img
              src={process.env.REACT_APP_LOGO}
              alt="#"
              className={styles.logo}
            />
            {/* <h1 className={styles.logoText}>{}</h1> */}
          </div>
          {sidebar ? (
            <MdClose
              className={styles.close}
              onClick={() => setSidebar((prev) => !prev)}
            />
          ) : (
            <AiOutlineAlignRight
              className={styles.hamburger}
              onClick={() => setSidebar((prev) => !prev)}
            />
          )}
        </div>
      </section>
    </>
  );
};

export default Header;
